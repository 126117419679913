// import { useEffect, useState } from "react";
// import { getAllDepartments } from "../../../../../../../../api/services/DepartmentServices";
// import { ERROR } from "../../../../../../../../components/general/app-toast/AppToast";
// import UseAppToast from "../../../../../../../../helpers/hooks/use-app-toast";
// import { UPDATE_PROP } from "../../../../../../../../store/ActionTypes";
// import { INITIAL_STATE as inital } from "../../../../../../../../helpers/RtCommon";
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../../../../../../store/Actions";
import { useNavigate } from "react-router";
import { useRef } from "react";
import { DELETE_PROP, UPDATE_PROP } from "../../../../../../store/ActionTypes";
import { updateOrCreateAuditTemplates } from "../../../../../../api/services/AuditServices";
import { IMAGES_BASE_URL } from "../../../../../../config";
import { useMemo } from "react";
import { AssigneeColumn } from "./AssigneeColumn";
import UseAppToast from "../../../../../../helpers/hooks/use-app-toast";
import { ERROR } from "../../../../../../components/general/app-toast/AppToast";
import { INITIAL_STATE as initial_table_state } from "../../../../../../helpers/RtCommon";
import { isBuffer } from "lodash";
import { INITIAL_STATE as initalSurvey } from "../../../../../../store/Constants";
import { useTranslation } from "react-i18next";

export const INITIAL_STATE = {
    addAssigneeModal: false,
    showDeleteAlert: false,
    assigneeId: null,
    assigneeValue: null,
    saveLoading: false,
};
const Logic = (dispatch, state, prop) => {
    const { t, i18n } = useTranslation();
    
    let navigate = useNavigate();
    const hiddenFileInput = useRef(null);
    const assignees = useSelector((state) => {
        return {
            ...initial_table_state,
            results: state.survey.surveyDetails.assignees,
        };
    });
    const { addToast } = UseAppToast();
    const reduxDispatch = useDispatch();
    const reduxState = useSelector((state) => state.survey);
    console.log("reduxState");
    console.log(reduxState);
    const updateAuditDetails = (auditPayload) => {
        let payload = [];
        let propPrefix = `survey`;
        auditPayload.forEach((element) => {
            payload.push({
                type: element.type,
                prop: propPrefix + `.${element.prop}`,
                value: element.value,
            });
        });
        console.log("Payload sent to Redux:", payload);

        reduxDispatch(updateState(payload));
    };
    const onchange = (e, prop) => {
        updateAuditDetails([
            {
                type: UPDATE_PROP,
                prop: prop,
                value: e.target.value,
            },
        ]);
    };

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };
    

    const actionUpdateApprovalReq = (approval_req, id) => {
        const index = reduxState.surveyDetails.assignees.findIndex((x) => {
            return x.id === id;
        });

        updateAuditDetails([
            {
                type: UPDATE_PROP,
                prop: `surveyDetails.assignees.${index}.approval_req`,
                value: !approval_req,
            },
        ]);
    };
    const deleteAssignee = (id) => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: "showDeleteAlert",
                    value: true,
                },
                {
                    type: UPDATE_PROP,
                    prop: "assigneeId",
                    value: id,
                },
            ],
        });
    };
    const updateAssignee = (value) => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: "addAssigneeModal",
                    value: true,
                },
                {
                    type: UPDATE_PROP,
                    prop: "assigneeValue",
                    value: value,
                },
            ],
        });
    };
    const HandleCloseDelete = () => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: "showDeleteAlert",
                    value: false,
                },
            ],
        });
    };
    const HandelDelete = () => {
        const index = reduxState.surveyDetails.assignees.findIndex((x) => {
            return x.id === state.assigneeId;
        });
        updateAuditDetails([
            {
                type: DELETE_PROP,
                prop: `surveyDetails.assignees.${index}`,
            },
        ]);

        HandleCloseDelete();
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: "assigneeId",
                    value: null,
                },
            ],
        });
    };
    const actions = () => {
        return {
            updateAuditDetails: updateAuditDetails,
            actionUpdateApprovalReq: actionUpdateApprovalReq,
            delete: deleteAssignee,
            update: updateAssignee,
        };
    };
   
    const generateSrc = (file) => {
        if (!file) return "";
        if (typeof file === "string") return IMAGES_BASE_URL + file;

        return URL.createObjectURL(file);
    };
    let columns = useMemo(() => AssigneeColumn(actions(), t), [state, i18n.language]);
    return {
        actions: {
            onchange,
            handleClick,
            actionUpdateApprovalReq,
            HandleCloseDelete,
            HandelDelete,
            generateSrc,
            // saveAuditTemplate,
            updateAuditDetails,
        },
        hiddenFileInput,
        columns,
        assignees,
        reduxState,
        navigate,
    };
};

export default Logic;



 
    // Removing this function (saveAuditTemplate) as I think its not needed - Murtaza Eliyas 2024-07-29
    // const saveAuditTemplate = () => {
    //     const formData = new FormData();
    //     formData.append("name", reduxState.surveyDetails.name);
    //     formData.append("short_name", reduxState.surveyDetails.short_name);
    //     formData.append(
    //         "json_structure",
    //         JSON.stringify({
    //             assignees: reduxState.surveyDetails.assignees,
    //             sections: reduxState.sections,
    //         })
    //     );
    //     // formData.append("sign_label", reduxState.surveyDetails.sign_label);
    //     formData.append("sign_required", reduxState.surveyDetails.sign_required);
    //     formData.append("show_sign", reduxState.surveyDetails.show_sign);
    //     formData.append("show_incharge_sign", reduxState.surveyDetails.show_incharge_sign);
    //     formData.append("incharge_sign_required", reduxState.surveyDetails.incharge_sign_required);
    //     if (reduxState.surveyDetails.sign_label) {
    //         formData.append("sign_label", reduxState.surveyDetails.sign_label);
    //     }
    //     if (reduxState.surveyDetails.incharge_sign_label) {
    //         formData.append("incharge_sign_label", reduxState.surveyDetails.incharge_sign_label);
    //     }

    //     formData.append("image", reduxState.surveyDetails.image);
    //     if (reduxState.surveyDetails.department_id) {
    //         formData.append("department_id", reduxState.surveyDetails.department_id?.value);
    //     }

    //     if (prop.id) {
    //         formData.append("id", prop.id);
    //     }
    //     dispatch({
    //         payload: [
    //             {
    //                 type: UPDATE_PROP,
    //                 prop: "saveLoading",
    //                 value: true,
    //             },
    //         ],
    //     });
    //     updateOrCreateAuditTemplates(
    //         (res) => {
    //             addToast({
    //                 type: "success",
    //                 description: `${prop.id ? "Update" : "Add"} Template Successfully`,
    //                 title: "Success",
    //             });

    //             reduxDispatch({
    //                 type: UPDATE_PROP,
    //                 prop: "survey",
    //                 value: (v) => {
    //                     return {
    //                         ...v,
    //                         surveyDetails: {
    //                             ...initalSurvey.survey.surveyDetails,
    //                         },
    //                         sections: [],
    //                         selectedQuestion: null,
    //                     };
    //                 },
    //             });
    //             navigate("/forms-checklists");
    //             console.log(res);
    //         },
    //         (error) => {
    //             addToast({
    //                 type: ERROR,
    //                 description: error.message,
    //                 title: "Error",
    //             });
    //             dispatch({
    //                 payload: [
    //                     {
    //                         type: UPDATE_PROP,
    //                         prop: "saveLoading",
    //                         value: false,
    //                     },
    //                 ],
    //             });
    //         },
    //         (f) => {},
    //         { formData }
    //     );
    // };
