import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useRef, useState } from "react";
import AppButton from "../../../../../../../../components/general/app-button/AppButton";
import AppInput from "../../../../../../../../components/general/app-input/AppInput";
import Label from "../../../../../../../../components/general/label/Label";
import styles from "./AddNewItem.module.css";
import { useTranslation } from "react-i18next";

const AddNewItem = ({ onAdd, toggleButtonText, labelstyle, textarea }) => {
    const [showForm, setShowForm] = useState(false);
    const [text, setText] = useState("");
    const inputRef = useRef();
    const { t } = useTranslation();
    const handleAddTask = useCallback(() => {
        if (text.trim().length < 1) {
            return;
        }
        // inputRef.current.focus();
        onAdd(text);
        setShowForm(false);
        setText("");
    }, [onAdd, text]);

    const handleInputKeyPress = useCallback(
        (event) => {
            if (event.key === "Enter") {
                handleAddTask();
            }
        },
        [handleAddTask]
    );

    const handleCancel = () => {
        setShowForm(true);
        setText("");
    };
    return (
        <div>
            {showForm && (
                <Label className={`${styles.label} ${labelstyle}`}>
                    <AppInput
                        containerStyle={{
                            flexDirection: "row-reverse",
                            alignItems: "baseline",
                        }}
                        type="text"
                        className={styles.input}
                        onChange={(e) => setText(e.target.value)}
                        onKeyPress={handleInputKeyPress}
                        value={text}
                        textarea={textarea}
                        ref={inputRef}
                        // onTranslation
                    >
                        <AppButton
                            className="buttonIcon"
                            icon={
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{
                                        color: "var(--primary)",
                                    }}
                                />
                            }
                            onClick={() => {
                                setShowForm(false);
                            }}
                            style={{
                                // borderLeft: "1px solid var(--primary)",
                                borderRadius: 0,
                                padding: "10px 15px",
                            }}
                        />
                    </AppInput>
                </Label>
            )}
            <AppButton
                className="primarySmallButton"
                icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: "0px 5px" }} />}
                onClick={() => (showForm ? handleAddTask() : handleCancel())}
                value={showForm ? t("add") : toggleButtonText}
            />
        </div>
    );
};

export default AddNewItem;
