import AppButton from "../../../../components/general/app-button/AppButton";
import Card from "../../../../components/general/card/Card";
import Title from "../../../../components/general/title/Title";
import AppInput from "../../../../components/general/app-input/AppInput";
import Label from "../../../../components/general/label/Label";
import React, { useRef, useState } from "react";
import Styles from "./AddDepartment.module.css";
import useLogic from "../../../../helpers/hooks/use-logic";
import Logic, { INITIAL_STATE } from "./logic";
import LoadingSpinner from "../../../../components/general/loading-spinner/LoadingSpinner";
import useValidator from "../../../../helpers/hooks/use-validator";
import ValidationProvider from "../../../../components/general/validation-provider/ValidationProvider";
import { useTranslation } from "react-i18next";
import TranslationModal from "../../../../components/general/translation-modal/TranslationModal";
import { UPDATE_PROP } from "../../../../store/ActionTypes";

const AddDepartment = () => {
  const { t } = useTranslation();
  const { state, updateState, handleUpdateOrCreate, navigate, params } =
    useLogic({
      INITIAL_STATE,
      Logic,
    });
  const { errors, onBlur, onChange, handleSubmit } = useValidator({
    defaultValues: {
      ...state.form,
    },
    setValue: (nextFormState) => {
      updateState({
        prop: "form",
        value: nextFormState,
      });
    },
    onSubmit: handleUpdateOrCreate,
    validations: {
      name: {
        required: true,
        validation_name: "Department",
      },
      // shortName: {
      //     required: true,
      //     validation_name: "Short name",
      // },
    },
  });
  const translationModalRef = useRef();
  const [currentField, setCurrentField] = useState({});
  return (
    <div className={Styles.container}>
      <div className={Styles.title}>
        <Title
          title={`${params.get("id") ? t("update") : t("add")} ${t(
            "department"
          )}`}
        ></Title>
      </div>
      <Card style={{ padding: "20px 10px" }}>
        <LoadingSpinner isLoading={state.pageLoading} error={state.error}>
          <div className={Styles.formContainer}>
            <div style={{ width: "50%", marginBottom: "60px" }}>
              <div className={Styles.profilePictureContainer}>
                {/* <div style={{ flex: 1 }}>
                                    <img
                                        src={require("../../../../assets/images/defaultProfilePicture.png")}
                                        width={"100%"}
                                        height={"auto"}
                                        style={{ borderRadius: "70px" }}
                                    />
                                </div> */}
                <div style={{ flex: 1 }}>
                  <div className={Styles.groupControl}>
                    <ValidationProvider
                      error={errors.name}
                      render={({ requiredTitle }) => (
                        <Label
                          name={requiredTitle(t("department"))}
                          className={Styles.label}
                        >
                          <AppInput
                            type="text"
                            className={Styles.input}
                           
                            onChange={(e) => {
                              const updatedValue = e.target.value;
                              console.log("Updated name:", updatedValue);
                              onChange("name", updatedValue);
                              let updatedTranslations = {
                                ...state.form?.translations,
                                name: {
                                  ...state.form?.translations["name"],
                                  [t('langCode')]: updatedValue,
                                },
                              };
                              updateState({
                                prop: "form",
                                value: {
                                  ...state.form,
                                  // name: updatedValue,
                                  translations: updatedTranslations,
                                },
                              });
                            }}
                            value={state.form.translations["name"]?.[t("langCode")] ||state.form.name}
                            onTranslation={() => {
                              if (
                                state.form &&
                                state.form.translations &&
                                state.form.translations["name"]
                              ) {
                                console.log("State form:", state.form);
                                console.log(
                                  "State form translations:",
                                  state.form?.translations
                                );

                                setCurrentField({
                                  column: "name",
                                  translations: state.form.translations["name"],
                                });
                                translationModalRef?.current?.toggleModal();
                              } else {
                                console.error(
                                  "Translations or 'name' not found in form state"
                                );
                              }
                            }}
                            onBlur={(e) => onBlur("name")}
                          />
                        </Label>
                      )}
                    />
                  </div>
                  {/* <div className={Styles.groupControl}>
                                        <ValidationProvider
                                            error={errors.shortName}
                                            render={({ requiredTitle }) => (
                                                <Label
                                                    name={t("shortName")}
                                                    className={Styles.label}
                                                >
                                                    <AppInput
                                                        type="text"
                                                        className={Styles.input}
                                                        value={state.form.shortName}
                                                        onChange={(e) =>
                                                            onChange("shortName", e.target.value)
                                                        }
                                                        onBlur={(e) => onBlur("shortName")}
                                                    />
                                                </Label>
                                            )}
                                        />
                                    </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className={Styles.actionButton}>
            <AppButton
              className="primaryButton"
              type="button"
              value={t("save")}
              onClick={handleSubmit}
              isLoading={state.saveLoading}
            />
            <AppButton
              className="whiteButton"
              onClick={() => navigate("/portal/departments")}
              value={t("cancel")}
              style={{ margin: "0px 15px" }}
            />
          </div>
        </LoadingSpinner>
      </Card>
      <TranslationModal
        ref={translationModalRef}
        onSave={(newObj) => {
          console.log("Saving new translation:", newObj);

          const updatedTranslations = {
            ...state.form.translations,
            [newObj.column]: {
              en: newObj.translations.en,
              ar: newObj.translations.ar,
            },
          };

          const updatedForm = {
            ...state.form,
            translations: updatedTranslations,
            [newObj.column]: newObj.translations.en,
          };

          updateState({
            prop: "form",
            value: updatedForm,
          });

          console.log("Updated form after saving:", updatedForm);

          translationModalRef?.current?.toggleModal();
        }}
        translationObj={currentField}
      />
    </div>
  );
};
export default AddDepartment;
