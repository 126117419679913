import React, { useRef, useEffect } from 'react';
// import CryptoJS from "crypto-js";
// import { AUTHENTICATION_TOKEN, PRIVILEGES } from "./constants/StaticKeys";
import { ENCRYPT_KEY } from './constants/StaticKeys';
import CryptoJS from 'react-native-crypto-js';
import moment from 'moment';

export const useEffectSkipFirst = (callback, dependencies) => {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      callback();
    }
  }, [...dependencies]);
};
export const debounce = (fn, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};
export const encrypted = (str) => {
  let JsonFormatter = {
    stringify: function (cipherParams) {
      // create json object with ciphertext
      var jsonObj = {
        ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
      };
      // optionally add iv or salt
      if (cipherParams.iv) {
        jsonObj.iv = cipherParams.iv.toString();
      }
      if (cipherParams.salt) {
        jsonObj.s = cipherParams.salt.toString();
      }
      // stringify json object
      return JSON.stringify(jsonObj);
    },
    parse: function (jsonStr) {
      // parse json string
      var jsonObj = JSON.parse(jsonStr);
      // extract ciphertext from json object, and create cipher params object
      var cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct),
      });
      // optionally extract iv or salt
      if (jsonObj.iv) {
        cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
      }
      if (jsonObj.s) {
        cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
      }
      return cipherParams;
    },
  };

  let encrypted_str = CryptoJS.AES.encrypt(JSON.stringify(str), ENCRYPT_KEY, {
    format: JsonFormatter,
  }).toString();

  return encrypted_str;
};

export const decrypted = (encryptedStr) => {
  let JsonFormatter = {
    stringify: function (cipherParams) {
      var jsonObj = {
        ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
      };
      if (cipherParams.iv) {
        jsonObj.iv = cipherParams.iv.toString();
      }
      if (cipherParams.salt) {
        jsonObj.s = cipherParams.salt.toString();
      }
      return JSON.stringify(jsonObj);
    },
    parse: function (jsonStr) {
      var jsonObj = JSON.parse(jsonStr);
      var cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct),
      });
      if (jsonObj.iv) {
        cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
      }
      if (jsonObj.s) {
        cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
      }
      return cipherParams;
    },
  };
  let decrypted_str = CryptoJS.AES.decrypt(encryptedStr, ENCRYPT_KEY, {
    format: JsonFormatter,
  });
  let decrypted_json = decrypted_str.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted_json);
};

export const userEncryption = (data) => {
  let encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    ENCRYPT_KEY,
  ).toString();
  //   alert(encrypted);
  //   debugger;
  return encrypted;
};

export const userDecryption = (data) => {
  let decrypted = CryptoJS.AES.decrypt(data, ENCRYPT_KEY).toString(
    CryptoJS.enc.Utf8,
  );
  return JSON.parse(decrypted);
};

/* This fuction is written to help map SimpleTable filters to match Gawda Old Table specifications
 * Gawda APIs need {key:"", sign:"", value:"", value2:""}
 * SimpleTable gives {id:"", operator:"", value:""}
 * Function loops on filtes and converts variables
 */
export const translateFilterArray = (filters) => {
  let newFilters = [];
  filters.forEach((f, index) => {
    let nf = {};
    nf.key = f.id;
    nf.sign = f.operator;
    nf.value = f.value;
    nf.value2 = f.value2;
    newFilters.push(nf);
  });
  return newFilters;
};

export const extractErrorMessage = (error) => {
  let message = error.message;
  if (
    error?.response?.data?.message &&
    typeof error?.response?.data?.message == 'string'
  ) {
    message = error?.response?.data?.message;
  }
  return message;
};

export const daysBetween = (first, second) => {
  // Copy date parts of the timestamps, discarding the time parts.
  var one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
  var two = new Date(second.getFullYear(), second.getMonth(), second.getDate());

  // Do the math.
  var millisecondsPerDay = 1000 * 60 * 60 * 24;
  var millisBetween = two.getTime() - one.getTime();
  var days = millisBetween / millisecondsPerDay;

  // Round down.
  return Math.floor(days);
};
export const whereIsNowFromRange = (startDate, startTime, endDate, endTime) => {
  // Convert input dates and times into JavaScript Date objects

  // Create moment objects for the start and end date-times
  const startDateTime = moment(startDate).set({
    hour: parseInt(startTime.split(':')[0], 10),
    minute: parseInt(startTime.split(':')[1], 10),
    second: parseInt(startTime.split(':')[2], 10),
  });

  const endDateTime = moment(endDate).set({
    hour: parseInt(endTime.split(':')[0], 10),
    minute: parseInt(endTime.split(':')[1], 10),
    second: parseInt(endTime.split(':')[2], 10),
  });

  const now = moment();
  // Check if the current date and time is within the range
  if (now.isBetween(startDateTime, endDateTime, null, '[]')) {
    // "now is within the range";
    return 0;
  } else if (now.isBefore(startDateTime)) {
    // "now is before the range";
    return -1;
  } else {
    // "now is after the range";
    return 1;
  }
};
