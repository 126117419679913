export const INITIAL_STATE = {
  user: null,
  toasts: [],
  survey: {
    saveLoading: false,
    id: null,
    surveyDetails: {
      show_sign: 0,
      sign_required: 0,
      show_incharge_sign: 0,
      incharge_sign_required: 0,
      image: '',
      assignees: [],
      name: '',
      short_name: '',
      sign_label: '',
      incharge_sign_label: '',
      department_id: {},
      translations: {},
    },
    sections: [
      {
        id: 1,
        name: 'Section 1',
        collapsed: false,
        subsections: [
          {
            id: 1,
            name: 'Subsection 1',
            collapsed: false,
            questions: [],
          },
        ],
      },
      // {
      //   id: 1,
      //   name: "hygin",
      //   collapsed: false,
      //   subsections: [
      //     {
      //       id: 1,
      //       name: "Subsection one",
      //       collapsed: false,
      //       questions: [
      //         {
      //           id: 1,
      //           name: "Rate branch flooe cleanLiness,check corner",
      //           type: "checkbox",
      //           questionHelp: "xxxx",
      //           mandatory: true,
      //           enabled: true,
      //           showImage: "",
      //           printSize: "",
      //           selectEmailAddress: [],
      //           notificationEmailRequired: true,
      //           actionNotificationEmailRequired: true,
      //           actionMandatory: true,
      //           selectedTriggerNotification: [],
      //           selectedTriggerPlan: [],
      //           tags: [],
      //           images: [],
      //           response: [
      //             {
      //               label: "yes",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "green",
      //               isChecked: false,
      //             },
      //             {
      //               label: "no",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: false,
      //             },
      //             {
      //               label: "maybe",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "orange",
      //               isChecked: false,
      //             },
      //           ],
      //         },
      //         {
      //           id: 2,
      //           name: "do all staff and customers wear masks?",
      //           type: "radio",
      //           questionHelp: "yyyy",
      //           mandatory: false,
      //           enabled: true,
      //           showImage: "",
      //           printSize: "",
      //           selectEmailAddress: [],
      //           notificationEmailRequired: true,
      //           actionNotificationEmailRequired: true,
      //           actionMandatory: true,
      //           selectedTriggerNotification: [],
      //           selectedTriggerPlan: [],
      //           tags: [],
      //           images: [],
      //           response: [
      //             {
      //               label: "yes",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "green",
      //               isChecked: false,
      //             },
      //             {
      //               label: "no",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: false,
      //             },
      //           ],
      //         },
      //         {
      //           id: 3,
      //           name: "do all staff and customers wear masks?",
      //           type: "radio",
      //           questionHelp: "zzzzz",
      //           mandatory: false,
      //           enabled: true,
      //           showImage: "",
      //           printSize: "",
      //           selectEmailAddress: [],
      //           notificationEmailRequired: true,
      //           actionNotificationEmailRequired: true,
      //           actionMandatory: true,
      //           selectedTriggerNotification: [],
      //           selectedTriggerPlan: [],
      //           tags: [],
      //           images: [],
      //           response: [
      //             {
      //               label: "yes",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "green",
      //               isChecked: false,
      //             },
      //             {
      //               label: "no",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: false,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       id: 2,
      //       name: "Subsection two",
      //       collapsed: false,
      //       questions: [
      //         {
      //           id: 4,
      //           name: "Rate branch flooe cleanLiness,check corner",
      //           type: "number",
      //           questionHelp: "yyyy",
      //           mandatory: false,
      //           enabled: true,
      //           showImage: "",
      //           printSize: "",
      //           selectEmailAddress: [],
      //           notificationEmailRequired: true,
      //           actionNotificationEmailRequired: true,
      //           actionMandatory: true,
      //           selectedTriggerNotification: [],
      //           selectedTriggerPlan: [],
      //           tags: [],
      //           images: [],
      //           response: [
      //             {
      //               label: "yes",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: false,
      //             },
      //             {
      //               label: "no",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: false,
      //             },
      //           ],
      //         },
      //         {
      //           id: 5,
      //           name: "do all staff and customers wear masks?",
      //           type: "text",
      //           questionHelp: "yyyy",
      //           mandatory: false,
      //           enabled: true,
      //           showImage: "",
      //           printSize: "",
      //           selectEmailAddress: [],
      //           notificationEmailRequired: true,
      //           actionNotificationEmailRequired: true,
      //           actionMandatory: true,
      //           selectedTriggerNotification: [],
      //           selectedTriggerPlan: [],
      //           tags: [],
      //           images: [],
      //           response: [
      //             {
      //               label: "yes",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: true,
      //             },
      //             {
      //               label: "no",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: false,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   id: 2,
      //   name: "staff",
      //   collapsed: false,
      //   subsections: [
      //     {
      //       id: 4,
      //       name: "Staff subsection1",
      //       collapsed: true,
      //       questions: [
      //         {
      //           id: 6,
      //           name: "Rate branch flooe cleanLiness,check corner",
      //           type: "checkbox",
      //           questionHelp: "yyyy",
      //           mandatory: false,
      //           enabled: true,
      //           showImage: "",
      //           printSize: "",
      //           selectEmailAddress: [],
      //           notificationEmailRequired: true,
      //           actionNotificationEmailRequired: true,
      //           actionMandatory: true,
      //           selectedTriggerNotification: [],
      //           selectedTriggerPlan: [],
      //           tags: [],
      //           images: [],
      //           response: [
      //             {
      //               label: "yes",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: false,
      //             },
      //             {
      //               label: "no",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: false,
      //             },
      //           ],
      //         },
      //         {
      //           id: 7,
      //           name: "are counter wipped clean periodecally?",
      //           type: "checkbox",
      //           questionHelp: "yyyy",
      //           mandatory: false,
      //           enabled: true,
      //           showImage: "",
      //           printSize: "",
      //           selectEmailAddress: [],
      //           notificationEmailRequired: true,
      //           actionNotificationEmailRequired: true,
      //           actionMandatory: true,
      //           selectedTriggerNotification: [],
      //           selectedTriggerPlan: [],
      //           tags: [],
      //           images: [],
      //           response: [
      //             {
      //               label: "yes",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: false,
      //             },
      //             {
      //               label: "no",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: false,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   id: 3,
      //   name: "product",
      //   collapsed: false,
      //   subsections: [
      //     {
      //       id: 5,
      //       name: "product subsection1",
      //       collapsed: false,
      //       questions: [
      //         {
      //           id: 8,
      //           name: "Rate branch flooe cleanLiness,check corner",
      //           type: "checkbox",
      //           questionHelp: "yyyy",
      //           mandatory: false,
      //           enabled: true,
      //           showImage: "",
      //           printSize: "",
      //           selectEmailAddress: [],
      //           notificationEmailRequired: true,
      //           actionNotificationEmailRequired: true,
      //           actionMandatory: true,
      //           selectedTriggerNotification: [],
      //           selectedTriggerPlan: [],
      //           tags: [],
      //           images: [],
      //           response: [
      //             {
      //               label: "yes",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: false,
      //             },
      //             {
      //               label: "no",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: false,
      //             },
      //           ],
      //         },
      //         {
      //           id: 9,
      //           name: "are counter wipped clean periodecally?",
      //           type: "checkbox",
      //           questionHelp: "yyyy",
      //           mandatory: false,
      //           enabled: true,
      //           showImage: "",
      //           printSize: "",
      //           selectEmailAddress: [],
      //           notificationEmailRequired: true,
      //           actionNotificationEmailRequired: true,
      //           actionMandatory: true,
      //           selectedTriggerNotification: [],
      //           selectedTriggerPlan: [],
      //           tags: [],
      //           images: [],
      //           response: [
      //             {
      //               label: "yes",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: false,
      //             },
      //             {
      //               label: "no",
      //               score: 10,
      //               hasCamera: 1,
      //               hasComment: 1,
      //               color: "red",
      //               isChecked: false,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
    selectedQuestion: null,
    images: [],
    masterData: {
      /* Considering removing number text and date since they do not have any score effect. just for reporting.  */
      questionTypes: ['radio', 'checkbox', 'number', 'text', 'date'],
      dataShowImage: ['below', 'behind'],
      dataPrintSize: ["don't print", 'print'],
      dataSelectEmailAddress: ['zeinab@gmail.com', 'xx@gmail.com'],
      // triggerNotification: ["good", "acceptable"],
      // triggerPlan: ["yes", "no"],
      dataTags: ['question1', 'question2'],
      departments: [],
    },
  },
};
