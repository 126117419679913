import { useNavigate } from "react-router-dom";
import { register } from "../../api/services/UserServices";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../store/ActionTypes";

export const INITIAL_STATE = {
  form: {
    // company_name: "aaa",
    // description: "aaa",
    // commercial_registration: "56432",
    // number_of_employees: "5",

    // tax_registration: "234678432",
    // industry: "Food and Beverages",
    // address: "21 at some address in some area in some city in some country",
    // sector: "Food",

    // name: "aaaah yana",
    // first_name: "unit testing company",
    // short_name: "unit testing company",
    // last_name: "unit testing company",
    // email: "aaaahyana@gmail.com",
    // contact_person_name: "Pops",
    // contact_person_mobile: "01156199901",
    // contact_person_whatsapp: "01156199901",
    // contact_person_email: "aaaahyana@gmail.com",
    // job_title: "yarab",
    company_name: "",
    translations:{
      company_name:{
           en:"",
           ar:""
      },
      first_name:{
        en:"",
        ar:""
      },
      last_name:{
        en:"",
        ar:""
      },
      contact_person_name:{
        en:"",
        ar:""
      }
    },
    description: "",
    name: "",
    first_name: "",
    short_name: "",
    last_name: "",
    email: "",
    commercial_registration: "",
    tax_registration: "",
    number_of_employees: "",
    sector: "",
    industry: "",
    address: "",
    contact_person_name: "",
    contact_person_mobile: "",
    contact_person_whatsapp: "",
    contact_person_email: "",
    job_title: "",
  },
  loading: false,
};
const Logic = (dispatch, state) => {
  const navigate = useNavigate();
  const { addToast } = UseAppToast();

  const registerHandler = () => {
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `loading`,
          value: true,
        },
      ],
    });

    register(
      (res) => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `loading`,
              value: false,
            },
          ],
        });

        // console.error(res)
        addToast({
          type: "success",
          description: "Register successfully",
          title: "Success",
        });
        navigate("/login");
      },
      (e) => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `loading`,
              value: false,
            },
          ],
        });
        addToast({
          type: "error",
          description: "Error",
          title: "Error",
        });
      },
      () => {},
      {
        ...state.form,
        name: state.form.first_name + " " + state.form.last_name,
        job_title: "Admin",
        short_name: state.form.first_name,
        translations:state.form?.translations,
        
      }
    );
  };
  return { actions: { registerHandler } };
};

export default Logic;
