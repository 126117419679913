import React, { useCallback, useRef, useState } from "react";
import AppInput from "../../../../../../../../components/general/app-input/AppInput";
import styles from "./ElementMaker.module.css";
import TranslationModal from "../../../../../../../../components/general/translation-modal/TranslationModal";

const areEqual = (prevProps, nextProps) => {
  return JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value);
};

const ElementMaker = ({
  value,
  handleChange,
  onTranslation,
  columnName,
  translations,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const translationModalRef = useRef();
  const [currentField, setCurrentField] = useState({});

  const handleUpdateTask = useCallback(
    (e) => {
      let text = e.target.value.trim();
      if (text.length < 1) {
        text = value;
        setNewValue(value);
      }
      setShowInput(false);
      handleChange(text);
    },
    [handleChange, value]
  );

  const handleInputKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        handleUpdateTask(event);
      }
    },
    [handleUpdateTask]
  );
  const handleTranslation = () => {
    setCurrentField({
      column: columnName,
      translations: translations,
    });
    translationModalRef?.current?.toggleModal();
  };

  return (
    <span>
      {showInput ? (
        <AppInput
          containerStyle={{
            flexDirection: "row-reverse",
            alignItems: "baseline",
          }}
          containerClassName={styles.inputContainer}
          type="text"
          className={styles.input}
          value={newValue}
          onChange={(e) => {
            // debugger;
            const updatedValue = e.target.value;
            setNewValue(updatedValue);
            handleChange(updatedValue);
          }}
          onBlur={handleUpdateTask}
          onKeyPress={handleInputKeyPress}
          // autoFocus
          onTranslation={handleTranslation}
        />
      ) : (
        <span
          onClick={() => setShowInput(true)}
          style={{
            display: "flex",
          }}
        >
          {value}
        </span>
      )}

      <TranslationModal
        ref={translationModalRef}
        onSave={(newObj) => {
          console.log("Before saving translations:", currentField);

          if (newObj && newObj.column && newObj.translations) {
            const updatedTranslations = newObj.translations;
            setNewValue(updatedTranslations.en);
            handleChange(updatedTranslations.en);
            onTranslation(updatedTranslations);
          } else {
            console.error("Invalid translation data:", newObj);
          }

          translationModalRef?.current?.toggleModal();
        }}
        translationObj={currentField}
      />
    </span>
  );
};

export default React.memo(ElementMaker, areEqual);
