import { useTranslation } from "react-i18next";
import AppButton from "../app-button/AppButton";
import AppModal from "../app-modal/AppModal";
import AppInput from "../app-input/AppInput";
import { useEffect, useImperativeHandle, useMemo, useState } from "react";
import { forwardRef } from "react";
import Label from "../label/Label";
import Styles from "./TranslationModal.module.css";
import { appLanguages } from "../../../helpers/constants/Languages";

const TranslationModal = forwardRef(
    ({ onHide, onSave, isLoading = false, translationObj }, ref) => {
        const { t } = useTranslation();
        const [isVisible, setIsVisible] = useState(false);
        const [translations, setTranslations] = useState(translationObj?.translations);
        // Toggle modal visibility
        const toggleModal = () => setIsVisible((prev) => !prev);

        // Expose the `toggleModal` function to the parent through the ref
        useImperativeHandle(ref, () => ({
            toggleModal,
        }));
        // Update the local state if initialParameter changes
          // Reset translations when closing the modal, and load new translations when opening
          useEffect(() => {
            if (isVisible) {
                // Load new translations when opening the modal
                setTranslations(translationObj?.translations);
            } else {
                // Reset translations when closing the modal
                setTranslations({});
            }
        }, [isVisible, translationObj]); // This will trigger when the modal opens/closes or translationObj changes

        return (
            <AppModal
            headerTitle={`Translate "${translationObj.column}"`}
                show={isVisible}
                onHide={() => {
                    onHide && onHide();
                    toggleModal();
                }}
                size="sm"
                closeBtn="true"
                footer={
                    <>
                        <AppButton
                            classes="defaultButton"
                            style={{ padding: "1px 12px" }}
                            onClick={() => {
                                onHide && onHide();
                                toggleModal();
                            }}
                            value={t("cancel")}
                        />
                        <AppButton
                            classes="defaultButton"
                            style={{ padding: "1px 12px" }}
                            onClick={() => {
                                onSave({ column: translationObj.column, translations });
                            }}
                            value={t("save")}
                            disabled={isLoading}
                            isLoading={isLoading}
                        />
                    </>
                }
                bodyStyle={{ paddingTop: 0 }}
                footerStyle={{ border: 0, paddingTop: 0 }}
                headerStyle={{ border: 0, paddingBottom: 0 }}
            >
                {
                    // Object.entries(translations)?.map(([key, value]) => {
                    Object.entries(appLanguages).map(([key, value]) => {
                        // return <AppInput type="text" value={value || ""} onChange={(e) => {}} />;
                        return (
                            <div className={Styles.groupControl} key={key} >
                                <Label name={value.label} className={Styles.label}>
                                    <AppInput
                                        type="text"
                                        className={Styles.input}
                                        onChange={(e) => {
                                            setTranslations((prevState) => ({
                                                ...prevState, // Spread the previous state
                                                [key]: e.target.value, // Update the key property eg. "ar"
                                            }));
                                        }}
                                        // onBlur={(e) => onBlur("short_name")}
                                        value={translations && translations[key]}
                                    />
                                </Label>
                            </div>
                        );
                    })
                }
            </AppModal>
        );
    }
);
const styles = {
    input: {
        width: "100%",
        border: "none",
        outline: "none",
        margin: "2px 0px",
        height: "100%",
    },
    label: {
        border: "1px solid var(--primary)",
        borderRadius: "6px",
        padding: "2px",
        width: "100%",
    },
};
export default TranslationModal;
