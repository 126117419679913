import { useEffect, useMemo, useState } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import NotFound from "./components/general/not-found/NotFound";
import ProtectedRoute from "./helpers/ProtectedRoute";
import { getUserFromLS } from "./helpers/Storage";
import AdminLayout from "./layouts/analytics-layout/AnalyticsLayout";
import DefaultLayout from "./layouts/default-layout/DefaultLayout";
import EmailTemplates from "./pages/advanced/email-templates/EmailTemplates";
import AddPermission from "./pages/advanced/permissions/add-permission/AddPermission";
import Permissions from "./pages/advanced/permissions/permission-list/Permissions";
import AddRole from "./pages/advanced/roles/add-role/AddRole";
import RolesList from "./pages/advanced/roles/roles-list/RolesList";
import ActionPlanN from "./pages/analytics/action-plan-new/action-plan-list";
import OverviewN from "./pages/analytics/overview-new";
import PerformanceN from "./pages/analytics/performance-new";
import Audits from "./pages/audits/Audits";
import ForgetPassword from "./pages/forget-password/ForgetPassword";
import LargeSection from "./pages/large-section/LargeSection";
import LoginCompany from "./pages/login-company/LoginCompany";
import Login from "./pages/login/Login";
import Logout from "./pages/logout/Logout";
import AddArea from "./pages/master-data/areas/add-area/AddArea";
import Areas from "./pages/master-data/areas/area-list/Areas";
import AddDepartment from "./pages/master-data/departments/add-department/AddDepartment";
import Departments from "./pages/master-data/departments/departments-list/Departmants";
import Checklists from "./pages/master-data/forms-checklists/checklists/Checklists";
import FormBuilder from "./pages/master-data/forms-checklists/form-builder/FormBuilder";
import AddLocation from "./pages/master-data/locations/add-location/AddLocation";
import Locations from "./pages/master-data/locations/locations-list/Locations";
import Register from "./pages/register/Register";
import ResetPassword from "./pages/reset-password/ResetPassword";
import AddSchedule from "./pages/schedule/add-schedule/AddSchedule";
import Schedule from "./pages/schedule/schedules-list/Schedule";
import Settings from "./pages/settings/settings";
import Splash from "./pages/splash";
import Subscriptions from "./pages/subscriptions";
import SwitchCompany from "./pages/switch-company/SwitchCompany";
import SwitchCompanyLogin from "./pages/SwitchCompany-login/SwitchCompany-login";
import TicketDetails from "./pages/tickets/ticket-details/TicketDetails";
import Tickets from "./pages/tickets/tickets-list/Tickets";
import AddUser from "./pages/users/add-user/AddUser";
import ChangePassword from "./pages/users/change-password/ChangePassword";
import Users from "./pages/users/users-list/Users";
import VerifyEmail from "./pages/verify-email/VerifyEmail";
import Plans from "./pages/plans";
import MessagesPage from "./pages/plans/subscribeSuccess";

const subscriptionRoutes = {
    path: "admin",
    element: (
        <ProtectedRoute>
            <AdminLayout />
        </ProtectedRoute>
    ),
    children: [
        {
            index: true, // This makes it the default route
            element: <Navigate to="subscriptions" />,
        },
        {
            path: "subscriptions",
            element: <Subscriptions />,
        },
        {
            path: "plans",
            element: <Plans />,
        },
    ],
};

const publicRoutes = [
    { path: "register", element: <Register /> },
    { path: "login", element: <Login /> },
    { path: "login-company", element: <LoginCompany /> },

    { path: "forget-password", element: <ForgetPassword /> },
    { path: "verify-email", element: <VerifyEmail /> },
    { path: "reset-password", element: <ResetPassword /> },
    { path: "logout", element: <Logout /> },
    { path: "switch-company", element: <SwitchCompany /> },
    { path: "switch-company-login", element: <SwitchCompanyLogin /> },

    { path: "/404", element: <NotFound /> },
    { path: "/", element: <Splash /> },
    { path: "*", element: <Navigate to="/" /> },
    {
        path: "success",
        element: <MessagesPage />,
    },
];
const filteredRoute = (user) => {
    // check for
    if (user && user.subscription) {
        if (user.subscription.status === "pending") {
            return [];
        }

        // difference between start and end dates
        let diff =
            (new Date(user.subscription.subscription_end_date) -
                new Date(user.subscription.subscription_start_date)) /
            (1000 * 60 * 60 * 24);
        if (diff < 1) {
            return [];
        }
    }
    let route = {
        path: "/portal",
        element: (
            <ProtectedRoute>
                <DefaultLayout />
            </ProtectedRoute>
        ),
        children: [
            // { path: "/", element: <Splash /> },
            // { path: "settings", element: <Settings /> },
            // { path: "subscriptions", element: <AnalyticsLayout /> },
            {
                path: "change-password/:id",
                element: <ChangePassword />,
            },
        ],
    };
    if (user != null) {
        let features = user.feature_permissions;
        /*
        {
            "USERS": true,
            "AUDITS": true,
            "SCHEDULE": true,
            "ANALYTICS": true,
            "MASTER_DATA": true,
            "ACTION_PLANS": true,
            "AUDIT_TEMPLATES": true,
            "ROLES_PERMISSIONS": true,
            "APP": true,
            "PORTAL": true,
            "COMPANY_INFO": true,
            "TICKETS": true
            "SUBSCRIPTIONS": true
        }
        */
        if (features.AUDITS) {
            route.children.push({ path: "audits", element: <Audits /> });
        }
        if (features.SCHEDULE) {
            route.children.push({ path: "schedule", element: <Schedule /> });
            route.children.push({
                path: "add-schedule",
                element: <AddSchedule />,
                children: [{ path: ":id", element: <AddSchedule /> }],
            });
        }
        if (features.MASTER_DATA) {
            route.children.push({ path: "locations", element: <Locations /> });
            route.children.push({
                path: "add-location",
                element: <AddLocation />,
                children: [{ path: ":id", element: <AddLocation /> }],
            });
            route.children.push({ path: "departments", element: <Departments /> });
            route.children.push({
                path: "add-department",
                element: <AddDepartment />,
                children: [{ path: ":id", element: <AddDepartment /> }],
            });

            route.children.push({ path: "areas", element: <Areas /> });
            route.children.push({
                path: "add-area",
                element: <AddArea />,
                // navigation is with /portal/add-area?id=1
                children: [{ path: ":id", element: <AddArea /> }],
            });
            // with id
        }

        if (features.AUDIT_TEMPLATES) {
            route.children.push({
                path: "form-builder",
                element: <FormBuilder />,
                children: [{ path: ":id", element: <FormBuilder /> }],
            });
            route.children.push({
                path: "forms-checklists",
                element: <Checklists />,
            });
        }

        if (features.ROLES_PERMISSIONS) {
            route.children.push({ path: "permissions", element: <Permissions /> });
            route.children.push({
                path: "add-permission",
                element: <AddPermission />,
                children: [{ path: ":id", element: <AddPermission /> }],
            });
            route.children.push({
                path: "roles",
                element: <RolesList />,
            });
            route.children.push({
                path: "add-role",
                element: <AddRole />,
                children: [{ path: ":id", element: <AddRole /> }],
            });
        }
        if (features.ACTION_PLANS) {
            route.children.push({
                path: "/portal/action-plan-new",
                element: <ActionPlanN />,
            });
        }
        if (features.USERS) {
            route.children.push({ path: "users", element: <Users /> });
            route.children.push({
                path: "add-user",
                element: <AddUser />,
                children: [{ path: ":id", element: <AddUser /> }],
            });
        }
        // analytics
        if (features.ANALYTICS) {
            route.children.push(
                ...[
                    { path: "overview", element: <OverviewN /> },
                    { path: "performance", element: <PerformanceN /> },
                ]
            );
        }

        if (features.ACTION_PLANS) {
            route.children.push({
                path: "/portal/action-plan-new",
                element: <ActionPlanN />,
            });
        }
        if (features.USERS) {
            route.children.push({ path: "users", element: <Users /> });
            route.children.push({
                path: "add-user",
                element: <AddUser />,
                children: [{ path: ":id", element: <AddUser /> }],
            });
        }
        // analytics
        if (features.ANALYTICS) {
            route.children.push(
                ...[
                    { path: "overview", element: <OverviewN /> },
                    { path: "performance", element: <PerformanceN /> },
                ]
            );
        }
        if (features.TICKETS) {
            route.children.push(
                ...[
                    {
                        path: "ticket-details/:ticketId",
                        element: <TicketDetails />,
                    },
                    { path: "tickets", element: <Tickets /> },
                ]
            );
        }
        if (features.COMPANY_INFO) {
            route.children.push(...[{ path: "settings", element: <Settings /> }]);
        }
    }
    return route;
};

const Routers = () => {
    const user = getUserFromLS(); // This could change frequently

    const routes = useMemo(() => {
        let filteredRoutes = filteredRoute(user);
        let r = [...publicRoutes, filteredRoutes];

        // If user is an admin, add subscriptionRoutes as a standalone route
        if (user?.is_admin === 1) {
            r.push(subscriptionRoutes);
        }

        return r;
    }, [user]);

    return useRoutes(routes);
};
export default Routers;
