import { useJsApiLoader } from "@react-google-maps/api";
import React, { useLayoutEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchAllAreas } from "../../../../api/services/AreaServices";
import {
    fetchSpecificLocation,
    fetchTimezones,
    updateOrCreateLocation,
} from "../../../../api/services/LocationServices";
import { ERROR, SUCCESS } from "../../../../components/general/app-toast/AppToast";
import UseAppToast from "../../../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../../../store/ActionTypes";
import { extractErrorMessage } from "../../../../helpers/Common";
export const INITIAL_STATE = {
    timezones: [],
    area: { data: [] },
    form: {
        name: "",
        translations: {
            name: { en: '', ar: '' },  
          },
        code: "",
        zipcode: "",
        longitude: 0,
        latitude: 0,
        radius: 10,
        address: "",
        to_emails: [],
        cc_emails: [],
        selectedTimezone: null,
        selectedArea: null,
    },
    saveLoading: false,
    pageLoading: false,
    error: null,
};
const Logic = (dispatch, state) => {
    let navigate = useNavigate();
    const { addToast } = UseAppToast();
    const { id } = useParams();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let duplicate = queryParams.get("duplicate") ?? false; // this will get the value of duplicate, e.g., "true"

    const setEmails = (field, value) => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: field,
                    value: value,
                },
            ],
        });
    };
    const getAreas = () =>
        fetchAllAreas(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `area`,
                            value: res.data.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 10000000,
            }
        );
    const handleUpdateOrCreate = () => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `saveLoading`,
                    value: true,
                },
            ],
        });
        // console.log(state.form.selectedTimezone);
        let payload = {
            ...state.form,
            timezone: state.form.selectedTimezone?.value,
            area_id: state.form.selectedArea?.value,
            translations:state.form.translations
            //   to_emails: ["email1@example.com", "email2@example.com"],
            //   cc_emails: ["email1@example.com", "email2@example.com"],
        };
        if (id && !duplicate) {
            payload = { ...payload, id: id };
        }
        updateOrCreateLocation(
            (res) => {
                let message = "Location is added successfully";
                if (id && !duplicate) {
                    message = "Location is updated successfully";
                }
                addToast({
                    type: SUCCESS,
                    description: message,
                    title: "Success",
                });
                navigate("/portal/locations");
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `saveLoading`,
                            value: false,
                        },
                    ],
                });
            },
            {
                ...payload,
            }
        );
        console.log("location payload", payload);
    };

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyD54C4K11A29EsIMcP5X7CjBaZen5K5o7o",
    });

    const [map, setMap] = React.useState(null);
    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds({
            lat: state.form.latitude,
            lng: state.form.longitude,
        });
        map.fitBounds(bounds);

        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log(position);
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `form.longitude`,
                                value: position.coords.longitude,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.latitude`,
                                value: position.coords.latitude,
                            },
                        ],
                    });
                },
                (error) => {
                    console.log(error);
                }
            );
        } else {
            // console.log("Geolocation not supported");
        }
    };

    const updateLocationOnClick = (lat, lng) => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `form.longitude`,
                    value: lng,
                },
                {
                    type: UPDATE_PROP,
                    prop: `form.latitude`,
                    value: lat,
                },
            ],
        });
    };
    useLayoutEffect(() => {
        // check the passed state
        if (id) {
            dispatch({
                payload: [
                    {
                        type: UPDATE_PROP,
                        prop: `pageLoading`,
                        value: true,
                    },
                ],
            });
            //   console.log("useeefect");
            fetchSpecificLocation(
                (res) => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `form.name`,
                                value: duplicate
                                    ? res.data.response.name + " - Copy"
                                    : res.data.response.name,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.code`,
                                value: res.data.response.code,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.zipcode`,
                                value: res.data.response.zipcode,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.address`,
                                value: res.data.response.address,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.to_emails`,
                                value: res.data.response.to_emails,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.cc_emails`,
                                value: res.data.response.cc_emails,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.selectedTimezone`,
                                value: {
                                    value: res.data.response.timezone,
                                    label: res.data.response.timezone_name,
                                },
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.selectedArea`,
                                value: {
                                    value: res.data.response?.area_id,
                                    label: res.data.response.area,
                                },
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.longitude`,
                                value: res.data.response.longitude,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.latitude`,
                                value: res.data.response.latitude,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.radius`,
                                value: res.data.response.radius,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.translations`,
                                value: res.data.response.translations,
                            },
                        ],
                    });
                },
                (error) => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `error`,
                                value: "cannot load this page",
                            },
                        ],
                    });
                    addToast({
                        type: ERROR,
                        description: extractErrorMessage(error),
                        title: "Error",
                    });
                },
                () => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `pageLoading`,
                                value: false,
                            },
                        ],
                    });
                },
                { id: id }
            );
        }
        getCurrentLocation();
        fetchTimezones(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `timezones`,
                            value: res.data.response,
                        },
                    ],
                });
            },
            (error) => {},
            () => {}
        );
        getAreas();
    }, []);

    return {
        actions: { handleUpdateOrCreate, setEmails, navigate },
        id,
        duplicate,
        onLoad,
        onUnmount,
        isLoaded,
        state,
        updateLocationOnClick,
    };
};

export default Logic;
